import React from "react";
import Image from "gatsby-image";
import { Link } from "gatsby";
import { Lineup, HeroLive, HeroHolding } from "../components/home";
import Particles from "react-particles-js";
import particlesConfig from "../vendor/particles.json";
import SEO from "../components/seo";

/* Home Page
============================================================================= */

const HomePage = ({ data }) => {
	// Config

	// Render
	return (
		<>
			<SEO title="Home" />
			<div className="w-full">
				<div className="flex flex-row items-center justify-center w-full px-0 py-0 lg:bg-beige bg-orange lg:px-32 md:px-16 md:py-20">
					<div className="container flex flex-row items-center justify-start w-full py-5 lg:justify-center lg:h-180 lg:py-0">
						<div
							className="relative w-full h-0"
							style={{ paddingBottom: "56.25%" }}
						>
							<iframe
								src="https://player.twitch.tv/?channel=isolaidfestival&parent=www.isolaidfestival.com"
								allowFullScreen
								className="absolute w-full h-full"
							></iframe>
						</div>
					</div>
				</div>
				{/* <div className="container mx-auto flex items-center justify-center py-6">
					<div className="lg:w-1/3 md:w-4/5 w-full h-0 relative" style={{ paddingBottom: "100%" }}>
						<Image 
							fluid={data.seeu.childImageSharp.fluid}
							className="w-full h-full"
							imgStyle={{ 
								objectFit:"cover"
							 }}
						/>
					</div>
				</div> */}
				<Lineup />
			</div>
		</>
	);
};

/* Page Query
============================================================================= */

export const pageQuery = graphql`
	query HomePageQuery {
		artwork: file(relativePath: { eq: "Artwork.png" }) {
			childImageSharp {
				fluid(maxWidth: 2500) {
					...GatsbyImageSharpFluid
				}
			}
		}
		leftTin: file(relativePath: { eq: "LefTin.png" }) {
			childImageSharp {
				fluid(maxWidth: 2500) {
					...GatsbyImageSharpFluid
				}
			}
		}
		rightTin: file(relativePath: { eq: "RightTin.png" }) {
			childImageSharp {
				fluid(maxWidth: 2500) {
					...GatsbyImageSharpFluid
				}
			}
		}
		goldseal: file(relativePath: { eq: "GoldSeal.png" }) {
			childImageSharp {
				fluid(maxWidth: 500) {
					...GatsbyImageSharpFluid
				}
			}
		}
		presents: file(relativePath: { eq: "Presents.png" }) {
			childImageSharp {
				fluid(maxWidth: 500) {
					...GatsbyImageSharpFluid
				}
			}
		}
		triangles: file(relativePath: { eq: "IsolateTriangleBackground.png" }) {
			childImageSharp {
				fluid(maxWidth: 2500) {
					...GatsbyImageSharpFluid
				}
			}
		}
		long: file(relativePath: { eq: "IsolateLong.png" }) {
			childImageSharp {
				fluid(maxWidth: 2500) {
					...GatsbyImageSharpFluid
				}
			}
		}
		seeu: file(relativePath: { eq: "seeu.jpeg" }) {
			childImageSharp {
				fluid(maxWidth: 750) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;

/* Export
============================================================================= */

export default HomePage;
