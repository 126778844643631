import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import moment from "moment-timezone";

/* Lineup
============================================================================= */

const Lineup = () => {
	// Config
	const data = useStaticQuery(
		graphql`
			query {
				allSanityConcert {
					nodes {
						label
						lineup {
							artist {
								name
								insta
								instaHandle
								slug
								startDate
							}
						}
					}
				}
			}
		`
	);
	const concerts = data.allSanityConcert.nodes;

	// Render Lineup Items
	const renderLineupItems = (lineup) => {
		return lineup.map((el, i) => {
			return (
				<LineupItem
					name={el.artist.name}
					instagram={el.artist.instaHandle}
					slug={el.artist.slug}
					insta={el.artist.insta}
					time={new moment(el.artist.startDate).format("HH:mm")}
				/>
			);
		});
	};

	// Render Concert
	const renderConcert = () => {
		let concertDate = concerts[0].label.split(" ");
		return (
			<div className="w-full flex flex-col lg:flex-row items-start justify-between lg:px-16 px-6">
				<div className="lg:w-1/2 w-full lg:pb-0 pb-6">
					<h2 className="ibm-plex lg:text-6xl text-4xl font-bold text-darkblue">
						{concertDate.shift().toUpperCase()}
						<span className="lg:text-3xl text-2xl lg:pl-4 pl-2 ibm-condensed font-normal">
							{concertDate.join(" ").toUpperCase()}
						</span>
					</h2>
					{renderLineupItems(concerts[0].lineup)}
				</div>
				<div className="lg:w-1/2 w-full lg:pb-0 pb-6">
					<h2 className="ibm-plex lg:text-6xl text-4xl font-bold text-darkblue">
						<span className="lg:text-3xl text-2xl lg:pl-4 pl-2 ibm-condensed font-normal"></span>
					</h2>
				</div>
			</div>
		);
	};

	// Render
	return (
		<div className="w-full bg-beige py-4">
			<div className="lg:mt-10 w-full container bg-orange bg-stipple container-padding mx-auto flex flex-col items-center justify-center">
				<h2
					className="text-beige text-5xl font-sans text-center ibm-condensed tracking-wide lg:mb-10 mb-12"
					style={{
						borderTop: "solid 3px",
						borderBottom: "solid 3px",
						borderColor: "#fee4d2",
						fontWeight: 700,
					}}
				>
					ISOL-AID LINEUP
				</h2>
				{renderConcert()}
				<Link
					to="/artists"
					className="text-white ibm-plex underline font-bold text-xl tracking-wide lg:pt-12 pt-8"
				>
					SEE ALL THE ARTISTS
				</Link>
			</div>
		</div>
	);
};

/* Isolate Item
============================================================================= */

const IsolateItem = ({ time = "2020-05-16T22:00:00", artist = "CLYPSO" }) => {
	// Config

	// Render
	return (
		<div className="flex flex-row items-start justify-start pt-5">
			<h2
				style={{ color: "rgb(0,255,88)" }}
				className="ibm-condensed font-bold lg:text-2xl sm:text-xl text-lg tracking-wide whitespace-no-wrap"
			>
				{new moment.tz(time, "Australia/Sydney")
					.local()
					.format("HH:mm")}
			</h2>
			<div className="flex flex-col items-start justify-start lg:pl-6 pl-4">
				<Link to={`/iso-late`}>
					<h2
						style={{ color: "rgb(0,255,88)" }}
						className="ibm-condensed font-bold lg:text-2xl sm:text-xl text-lg tracking-wide whitespace-no-wrap"
					>
						{artist}
					</h2>
				</Link>
				<Link
					to="/iso-late"
					style={{ color: "rgb(0,255,88)" }}
					className="ibm-condensed lg:text-xl sm:text-lg text-base tracking-wide whitespace-no-wrap break-words"
				>
					ISO-LATE
				</Link>
			</div>
		</div>
	);
};

/* Lineup Item
============================================================================= */

const LineupItem = ({
	time = "12:55 AEST",
	name = "WELCOME",
	slug = "aya-yves",
	insta = "",
	instagram = "@ISOLAIDFESTIVAL",
}) => {
	return (
		<div className="flex flex-row items-start justify-start pt-5">
			<h2 className="text-white ibm-condensed font-bold lg:text-2xl sm:text-xl text-lg tracking-wide whitespace-no-wrap">
				{time}
			</h2>
			<div className="flex flex-col items-start justify-start lg:pl-6 pl-4">
				<Link to={`/artists/${slug}`}>
					<h2 className="text-white ibm-condensed font-bold lg:text-2xl sm:text-xl text-lg tracking-wide whitespace-no-wrap">
						{name.toUpperCase()}
					</h2>
				</Link>
				<a
					href={insta}
					target="_blank"
					rel="noopener noreferrer"
					className="text-white ibm-condensed lg:text-xl sm:text-lg text-base tracking-wide whitespace-no-wrap break-words"
				>
					{instagram}
				</a>
			</div>
		</div>
	);
};

/* Export
============================================================================= */

export { Lineup };
